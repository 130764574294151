import { initializeApp  } from "@firebase/app"
import { getAuth } from 'firebase/auth'
import { getFirestore, collection, doc} from 'firebase/firestore'
import { getStorage} from 'firebase/storage'
import {getFunctions} from 'firebase/functions'

import {getAnalytics, logEvent} from 'firebase/analytics'

let config  = {
  apiKey: "AIzaSyC2dVm89hZdyVRo_6sUEoYno-7OBPgsrKo",
  authDomain: "uroavanza-web.firebaseapp.com",
  projectId: "uroavanza-web",
  storageBucket: "uroavanza-web.appspot.com",
  messagingSenderId: "8645901104",
  appId: "1:8645901104:web:8f29cb1773ef2b9bd126f5",
  measurementId: "G-LBKZCES5S9"
};

const firebase = initializeApp(config)

const firestore = getFirestore()
const auth = getAuth()
const functions = getFunctions()
const storage = getStorage()
const ColeccionLanding = doc(firestore, 'Landing/Contenido')

const ColeccionLandingImagenes = doc(firestore, 'Landing/Imágenes')
const Colección_blog = collection(firestore, 'Landing/Contenido/Blog')
const Colección_etiquetas = collection(firestore, 'Landing/Contenido/Etiquetas')
const analytics = getAnalytics()
logEvent(analytics)

export {
  functions,
  auth,
  storage,
  firebase,
  ColeccionLanding,
  ColeccionLandingImagenes,
  Colección_blog,
  Colección_etiquetas,
}
