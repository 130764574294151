<template lang='pug'>
v-responsive.pa-0(fluid )  
  v-app
   
    v-snackbar(:timeout='2000'  v-model="Ayudas.mostrar_snackbar" color='white'   style="justify-content: center; align-items: center; ")
     
      p.text-center(style="color: #6C2DA0; font-size: 15px; " ).font-weight-medium.flat {{ Ayudas.mensaje_de_snackbar }}
      .fila-boton.justify-center.align-items-center
        v-btn( color='white' text @click='Ayudas.mostrar_snackbar=false' )  Cerrar

    router-view
    //- v-overlay( v-model='cargando' opacity='1' color='#2A103F' )
    //-   img( :src='logocargando' height='100px') 
    
</template>
<script>
import { mapActions, mapState } from 'vuex'
import logocargando from './assets/cargando/logocargando.png'

export default {
data(){
  return{
    cargando: true,
    logocargando,
  }
},
created(){
  this.obtener_datos()
  this.obtener_entradas()
  this.obtener_etiquetas()
},
watch:{
  Contenido(){
    this.cargando= false
  }
},
computed:{
  ...mapState({
    Ayudas : ({Ayudas}) => Ayudas,
  }),
},
methods: {
  ...mapActions({
    obtener_datos: 'Landing_set/obtener',
    obtener_entradas: 'Blog/obtener_todos',
    obtener_etiquetas: 'Etiquetas/obtener_todos',
  }),
},
}
</script>
<style lang="sass" scoped>
.fade-enter-from, .fade-leave-to
    opacity: 0
.fade-enter-active, .fade-leave-active
    transition: opacity 0.5s ease-out
.moradofilasuperior
  color: #6C2DA0
  
.fila-boton
  padding-top: 10px
  display: flex
  justify-content: center
  border-radius: 15px
  .v-btn  
    background-color: #00AA7F
    color: white
</style>
<style>


  
</style>
<style lang="sass">

@font-face 
  font-family: 'Montserrat'
  src: url('./assets/fonts/Montserrat-Light.ttf')
  
a,h1, h2, h3, h4, h5, h6, h7, span, strong, p
  font-family: 'Montserrat'
  
</style>
