import Vue from 'vue'
import Router from 'vue-router'
import Raíz_principal from '../views/vistas/raíz_principal.vue'

Vue.use( Router )
const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      component: Raíz_principal,
      children: [
        {
          name: 'inicio',
          path: '',
          component: ()=>import('../views/vistas/vistas/inicio.vue'),
        },
        {
          name: 'citas',
          path: 'citas',
          component: ()=>import('../views/vistas/vistas/citas.vue'),
        },
        {
          name: 'especialistas',
          path: 'especialistas',
          component: ()=>import('../views/vistas/vistas/especialistas.vue'),
        },
        {
          name: 'contacto',
          path: 'contacto',
          component: ()=>import('../views/vistas/vistas/contacto.vue'),
        },
        {
          name: 'servicios',
          path: 'servicios',
          component: ()=>import('../views/vistas/vistas/servicios.vue'),
        },
      
      ]
    },
  ]
})
export default router